import React, { useState } from "react";
import { graphql, useStaticQuery } from 'gatsby';
import Typist from 'react-typist';
import Link from 'gatsby-link';
import get from 'lodash/get';

import './sidebar.css';


export default function Sidebar() {
  const data = useStaticQuery(graphql`
    query SidebarQuery {
      allContentfulPerson(filter: { name: { eq: "Shamroy Pellew" }}) {
        edges {
          node {
            name
            titles
            image {
              resize(width: 400, height: 400) {
                src
              }
            }
            resume {
              file {
                url
              }
            }
            email
            phone
            github
            linkedin
          }
        }
      }
    }
  `);

  const me = get(data, 'allContentfulPerson.edges[0].node');
  const [title, setTitle] = useState(me.titles[0]);

  const handleTypingDone = () => {
    const titles = [...me.titles];
    titles.splice(titles.indexOf(title), 1);
    setTitle(titles[Math.floor(Math.random() * titles.length)]);
  };

  return (
    <div id="sidebar">
      <div id="sidebar-inner">
        <figure id="sidebar-portrait">
          <img alt="" src={me.image.resize.src} />
        </figure>
        <h1 id="sidebar-heading">{me.name}</h1>
        <span id="sidebar-sub-heading">
          <Typist cursor={{ hideWhenDone: true, hideWhenDoneDelay: 200 }} avgTypingDelay={80} onTypingDone={handleTypingDone} key={title}>
            <Typist.Delay ms={2500} />
            <h3>{title}</h3>
            <Typist.Backspace count={title.length} delay={1500} />
          </Typist>
        </span>
        <div id="socials-container">
          <span className="socials">
            <Link to="/" data-tip="Portfolio"><i className="fas fa-th" /></Link>
            <Link to="/resume" data-tip="Resume"><i className="far fa-file-alt" /></Link>
            <a href={`mailto:${me.email}`} aria-label="Email" target="_blank" rel="noreferrer" data-tip="Email"><i className="far fa-envelope" /></a>
          </span>
          <span className="socials">
            <a href={`tel:${me.phone}`} target="_blank" rel="noreferrer" aria-label="Phone" data-tip="Phone"><i className="fas fa-phone" /></a>
            <a href={me.linkedin} target="_blank" rel="noreferrer" aria-label="LinkedIn" data-tip="LinkedIn"><i className="fab fa-linkedin" /></a>
            <a href={me.github} target="_blank" rel="noreferrer" aria-label="GitHub" data-tip="GitHub"><i className="fab fa-github" /></a>
          </span>
        </div>
      </div>
    </div>
  );
};
