import React, { Component } from 'react';
import { Header } from 'semantic-ui-react'; 
import Helmet from 'react-helmet';
import Sidebar from '../components/sidebar';

import './index.css';
import '../fonts/font-mfizz.css';
import 'semantic-ui-css/semantic.min.css';


export default class Layout extends Component {
  // componentDidUpdate = (prevProps, prevState) => {
  //   if (JSON.stringify(prevState.helmet) !== JSON.stringify(this.state.helmet)) {
  //     document.getElementById("container").scrollIntoView();
  //   }
  // }
  render() {
    const { title, hideHeader } = this.props;
    return (
      <div>
        <Helmet>
          <title>{title ? `${title} | shamroy.me` : `shamroy.me`}</title>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <script src="https://kit.fontawesome.com/e08df18706.js" crossorigin="anonymous"></script>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.14.0/devicon.min.css" />
        </Helmet>
        <div id="container">
          <Sidebar />
          <div id="main" style={hideHeader ? { padding: 0 } : {}}>
            {!hideHeader && <Header id="header" size="huge" textAlign="center">
              {title}
            </Header>}
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
};
